 .icon {
  border-radius: 50%;
  width: 90px;
  height: 90px;
  text-align: center;
}
.heading-022 {
    padding: .5em 0em;
    border-left: 20px solid #D17091;
    background-color: #f2f2f2;
    color: #333333;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
}
.ContensText {
    background-color: white;
    border: none; 
    color: #333333;
    font-size: 12pt;
    text-align: center;
}
.headingContensUP {
  color: #333333;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  position: relative;
}
