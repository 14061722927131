.App {
  text-align: center;
  width: 684px;
  background-color: white;
  margin:auto;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
font-family: 'BIZ UDPGothic', sans-serif;
font-family: 'Noto Sans JP', sans-serif;
}


.contentmiddle {
  flex: 1;
  background-color: #f9f9f9;
  overflow-y: scroll;
  max-height: calc(100vh - 185px);
font-family: 'BIZ UDPGothic', sans-serif;
font-family: 'Noto Sans JP', sans-serif;
  
}

.contentmiddle::-webkit-scrollbar {
	   width: 15px;
}

.contentmiddle::-webkit-scrollbar-track {
   background-color: white;
}

.contentmiddle::-webkit-scrollbar-thumb {
     background-color: #D17091;
}

.contentmiddle80{
   width: 80%;
   margin: 0 auto;
}
  

.Footerfixed {
  color: black;
}



.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
  
  .ContentsList{
    background-color: #FFFFFF;
}

  .StyleButton{
    background-color: #282c34;
}

.TextComponent {
  width: 50%; /* テキストフィールドの幅を半分に設定 */
}

/* セレクトフィールド */
.SelectComponent {
  width: 50%; /* セレクトフィールドの幅を半分に設定 */
}
